import "./template/style.css";
import $ from "jquery";
import home from "./home.js";
import imprintTemplate from "./template/imprint.html";
import tnbTemplate from "./template/tnb.html";
import nlSuccessTemplate from "./template/nl-success.html";
import nlErrorTemplate from "./template/nl-error.html";
import footerTemplate from "./template/footer.html";

if ("serviceWorker" in navigator) {
  // Use the window load event to keep the page load performant
  window.addEventListener("load", () => {
    navigator.serviceWorker.register("/service-worker.js");
  });
}

$(() => {
  switch (window.location.search) {
    case "?imprint":
      $("body").append([imprintTemplate, footerTemplate]);
      break;
    case "?tnb":
      $("body").append([tnbTemplate, footerTemplate]);
      break;
    case "?nl-success":
      $("html").addClass("h-100");
      $("body").addClass("h-100").append([nlSuccessTemplate, footerTemplate]);
      break;
    case "?nl-error":
      $("html").addClass("h-100");
      $("body").addClass("h-100").append([nlErrorTemplate, footerTemplate]);
      break;
    default:
      home();
  }
});
